<template>
  <div class="content" v-if="route.name == 'Beranda'">
    <Beranda/>
  </div>
  <div class="content" v-if="route.name == 'Login'">
    <Login/>
  </div>
  <div class="content" v-if="route.name == 'KontakKami'">
    <KontakKami/>
  </div>
  <div class="content" v-if="route.name == 'DaftarRegulasi'">
    <DaftarRegulasi/>
  </div>
  <div class="content" v-if="route.name == 'DaftarSungai'">
    <DaftarSungai/>
  </div>
  <div class="content" v-if="route.name == 'GIS'">
    <GIS/>
  </div>
  <div class="content" v-if="route.name == 'Dashboard'">
    <Dashboard/>
  </div>
  <div class="content" v-if="route.name == 'Slider'">
    <Slider/>
  </div>
  <div class="content" v-if="route.name == 'ProsesSlider'">
    <ProsesSlider/>
  </div>
  <div class="content" v-if="route.name == 'Mpasar'">
    <Mpasar/>
  </div>
  <div class="content" v-if="route.name == 'ProsesMpasar'">
    <ProsesMpasar/>
  </div>
  <div class="content" v-if="route.name == 'Regulasi'">
    <Regulasi/>
  </div>
  <div class="content" v-if="route.name == 'ProsesRegulasi'">
    <ProsesRegulasi/>
  </div>
  <div class="content" v-if="route.name == 'Prasarana'">
    <Prasarana/>
  </div>
  <div class="content" v-if="route.name == 'ProsesPrasarana'">
    <ProsesPrasarana/>
  </div>
  <div class="content" v-if="route.name == 'InfoBeranda'">
    <InfoBeranda/>
  </div>
  <div class="content" v-if="route.name == 'ProsesInfoBeranda'">
    <ProsesInfoBeranda/>
  </div>
  <div class="content" v-if="route.name == 'Sungai'">
    <Sungai/>
  </div>
  <div class="content" v-if="route.name == 'ProsesSungai'">
    <ProsesSungai/>
  </div>
  <div class="content" v-if="route.name == 'STA'">
    <STA/>
  </div>
  <div class="content" v-if="route.name == 'ProsesSTA'">
    <ProsesSTA/>
  </div>
  <div class="content" v-if="route.name == 'TMA'">
    <TMA/>
  </div>
  <div class="content" v-if="route.name == 'ProsesTMA'">
    <ProsesTMA/>
  </div>
  <div class="content" v-if="route.name == 'KategoriData'">
    <KategoriData/>
  </div>
  <div class="content" v-if="route.name == 'ProsesKategoriData'">
    <ProsesKategoriData/>
  </div>
  <div class="content" v-if="route.name == 'APIPeta'">
    <APIPeta/>
  </div>
  <div class="content" v-if="route.name == 'ProsesAPIPeta'">
    <ProsesAPIPeta/>
  </div>
  <div class="content" v-if="route.name == 'LayerPeta'">
    <LayerPeta/>
  </div>
  <div class="content" v-if="route.name == 'ProsesLayerPeta'">
    <ProsesLayerPeta/>
  </div>
  <div class="content" v-if="route.name == 'User'">
    <User/>
  </div>
  <div class="content" v-if="route.name == 'ProsesUser'">
    <ProsesUser/>
  </div>
</template>

<script>
import Beranda from './components/views/Publik/Beranda.vue';
import Login from './components/views/Publik/Login.vue';
import KontakKami from './components/views/Publik/KontakKami.vue';
import DaftarRegulasi from './components/views/Publik/DaftarRegulasi.vue';
import DaftarSungai from './components/views/Publik/DaftarSungai.vue';
import GIS from './components/views/Publik/GIS.vue';
import Dashboard from './components/views/Dashboard/Dashboard.vue';
import Slider from './components/views/Backoffice/Slider/Slider.vue';
import ProsesSlider from './components/views/Backoffice/Slider/ProsesSlider.vue';
import Regulasi from './components/views/Backoffice/Regulasi/Regulasi.vue';
import ProsesRegulasi from './components/views/Backoffice/Regulasi/ProsesRegulasi.vue';
import Prasarana from './components/views/Backoffice/Prasarana/Prasarana.vue';
import ProsesPrasarana from './components/views/Backoffice/Prasarana/ProsesPrasarana.vue';
import InfoBeranda from './components/views/Backoffice/InfoBeranda/InfoBeranda.vue';
import ProsesInfoBeranda from './components/views/Backoffice/InfoBeranda/ProsesInfoBeranda.vue';
import Sungai from './components/views/Backoffice/Sungai/Sungai.vue';
import ProsesSungai from './components/views/Backoffice/Sungai/ProsesSungai.vue';
import STA from './components/views/Backoffice/STA/STA.vue';
import ProsesSTA from './components/views/Backoffice/STA/ProsesSTA.vue';
import TMA from './components/views/Backoffice/TMA/TMA.vue';
import ProsesTMA from './components/views/Backoffice/TMA/ProsesTMA.vue';
import KategoriData from './components/views/Backoffice/KategoriData/KategoriData.vue';
import ProsesKategoriData from './components/views/Backoffice/KategoriData/ProsesKategoriData.vue';
import APIPeta from './components/views/Backoffice/APIPeta/APIPeta.vue';
import ProsesAPIPeta from './components/views/Backoffice/APIPeta/ProsesAPIPeta.vue';
import LayerPeta from './components/views/Backoffice/LayerPeta/LayerPeta.vue';
import ProsesLayerPeta from './components/views/Backoffice/LayerPeta/ProsesLayerPeta.vue';
import User from './components/views/Backoffice/User/User.vue';
import ProsesUser from './components/views/Backoffice/User/ProsesUser.vue';

import { useRoute } from 'vue-router';
import { watch } from 'vue';

export default {
  name: 'App',
  components: {
    Beranda,Login,KontakKami,DaftarRegulasi,DaftarSungai,GIS,Dashboard
    ,Slider,ProsesSlider,Regulasi,ProsesRegulasi,InfoBeranda,ProsesInfoBeranda
    ,Sungai,ProsesSungai,STA,ProsesSTA,User,ProsesUser,TMA,ProsesTMA
    ,KategoriData,ProsesKategoriData,LayerPeta,ProsesLayerPeta
    ,APIPeta,ProsesAPIPeta,Prasarana,ProsesPrasarana
  },
  mounted() {
    document.title = "WEB GIS FASILITAS JALAN";
  },
  setup() {
    const route = useRoute()
    watch(() => route.name, () => {
    });

    return { route };
  }
}
</script>

<style></style>
