<template>
    <div class="wrapper">
        <NavBar />
        <Menu />
        <!-- Spinner Start -->
        <div id="spinner" v-if="halamanloading"
            class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
            <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <!-- Spinner End -->
        <center>
            <div class="modal fade" id="costumModaldisclaimer" data-easein="flipBounceYIn" tabindex="-1" role="dialog"
                aria-labelledby="costumModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false"
                style="margin: auto;">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Dokumentasi</h4>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body text-justify">
                            <p>
                            <div class="col-sm-12">
                                <iframe src="" id="iframefile" style="width: 100%;height: 55vh;"></iframe>
                            </div>
                            </p>
                        </div>
                        <div class="modal-footer justify-content-between">
                            <button type="button" class="btn btn-danger btn-sm lebar" data-dismiss="modal"><i
                                    class="fas fa-window-close"></i> Tutup</button>
                        </div>
                    </div>
                    <!-- /.modal-content -->
                </div>
                <!-- /.modal-dialog -->
            </div>
            <!-- /.modal -->
        </center>
        <div class="content-wrapper py-3">
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6 wow fadeInDown">
                            <h5>STA Sungai</h5>
                        </div>
                        <div class="col-sm-6 wow fadeInDown">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item"><a href="/Dashboard">Home </a></li>
                                <li class="breadcrumb-item active">Pengaturan Data STA Sungai</li>
                            </ol>
                        </div>
                    </div><!-- /.row -->
                </div><!-- /.container-fluid -->
            </div>
            <!-- /.content-header -->
            <div class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-12 wow fadeInDown">
                            <div class="card cardputih">
                                <div class="card-header border-transparent">
                                    <div class="card-tools">
                                        <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                            <i class="fas fa-minus"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="card-body p-0">
                                    <div class="col-sm-12 text-center p-2">
                                        <button type="button" class="btn btn-danger3 btn-sm text-white lebar2"
                                            @click="prosesdata('Tambah')" data-toggle="tooltip" data-placement="left"
                                            title="Tambah data..">
                                            <i class="fas fa-plus"></i> Tambah
                                        </button>
                                        <button type="button" class="btn btn-success btn-sm text-white lebar2"
                                            @click="G_tableCSV('_Data_STA_')" data-toggle="tooltip" data-placement="left"
                                            title="Export data..">
                                            <i class="far fa-file-excel"></i> Export .xlsx
                                        </button>
                                    </div>
                                    <div class="col-sm-12 float-left pb-2 px-3">
                                        Jumlah Data: {{ G_numFormat(jumlahdata) }}
                                        <span style="float: right;">
                                            Halaman ke: <select v-model="halaman" @change="onChangeHalaman($event)">
                                                <option v-for="index in jumlahhalaman" :key="index">
                                                    {{ index }}</option>
                                            </select> dari {{ jumlahhalaman }}
                                        </span>
                                    </div>

                                    <div class="table-responsive table-wrapper px-3">
                                        <table class="table m-0" id="tabeldata">
                                            <thead>
                                                <tr>
                                                    <th style="width: 50px;">No</th>
                                                    <th style="width: 50px;">Opsi</th>
                                                    <th style="width: 100px;">Dokumentasi</th>
                                                    <th style="width: 80px;">Nama<br>Sungai</th>
                                                    <th style="width: 80px;">Nama</th>
                                                    <th style="width: 80px;">STA</th>
                                                    <th style="width: 80px;">Koordinat</th>
                                                    <th style="width: 80px;">Kinerja<br>Fisik</th>
                                                    <th style="width: 80px;">Kinerja<br>Fungsi</th>
                                                    <th style="width: 80px;">Nilai<br>Total</th>
                                                    <th style="width: 80px;">
                                                        Nilai<br>Kondisi<br>Fisik<br>vs<br>Kondisi<br>Fungsi</th>
                                                </tr>
                                            </thead>
                                            <template v-for="(datalist, urutlist) in datamaster" :key="urutlist">
                                                <tbody>
                                                    <tr>
                                                        <td :class="'text-center '+datalist.class">{{ urutlist + 1 }}</td>
                                                        <td :class="'text-center '+datalist.class">
                                                            <button type="button"
                                                                class="btn btn-primary3 btn-sm text-white lebar4"
                                                                @click="prosesdata(datalist.id)" data-toggle="tooltip"
                                                                data-placement="left" title="Edit data.."><i
                                                                    class="fas fa-edit"></i></button>
                                                            <button type="button"
                                                                class="btn btn-info3 btn-sm text-white lebar4"
                                                                @click="hapus(datalist.id)" data-toggle="tooltip"
                                                                data-placement="left" title="Hapus data.."><i
                                                                    class="fas fa-trash-alt"></i></button>
                                                        </td>
                                                        <td :class="'text-center '+datalist.class">
                                                            <img :src="folder_foto + datalist.filenya_penampang"
                                                                class="img-size-75 animation__wobble"
                                                                alt="InfoBeranda Image"
                                                                style="height: 100px;cursor:pointer"
                                                                @click="lihatlampiran(datalist.filenya_penampang)">
                                                        </td>
                                                        <td :class="datalist.class">
                                                            {{ datalist.NamaSungai }}
                                                        </td>
                                                        <td :class="'text-center '+datalist.class">
                                                            {{ datalist.NamaSTA }}
                                                        </td>
                                                        <td :class="'text-center '+datalist.class">
                                                            {{ datalist.STA }}
                                                        </td>
                                                        <td :class="datalist.class">
                                                            {{ datalist.LATITUDE }}<br>{{ datalist.LONGITUDE }}
                                                        </td>
                                                        <td :class="datalist.class"> {{ G_numFormatKoma(datalist.nilaikinerjafisik) }}</td>
                                                        <td :class="datalist.class"> {{ G_numFormatKoma(datalist.nilaikinerjafungsi) }}</td>
                                                        <td :class="datalist.class"> {{ G_numFormatKoma(datalist.nilaitotal) }}</td>
                                                        <td :class="datalist.class"> {{ datalist.kinerja }}</td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                            <tbody v-if="(datapesan == 'data kosong')">
                                                <tr>
                                                    <td colspan="11">Data masih kosong</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="card-footer p-3">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import $ from 'jquery';
import axios from 'axios';
import vSelect from 'vue-select';
import swal from 'sweetalert2';
import CryptoJS from 'crypto-js';
import { useRoute } from 'vue-router';
import { watch } from 'vue';
import xlsx from 'xlsx/dist/xlsx.full.min';
import Footer from "../../componen/Footer.vue";
import NavBar from "../../componen/NavBar.vue";
import Menu from "../../componen/Menu.vue";

import VueApexCharts from "vue3-apexcharts";
window.Apex.chart = { fontFamily: "Poppins-SemiBold, Arial, sans-serif" };
export default {
    setup() {
        const route = useRoute()
        watch(() => route.name, () => {
        });

        return { route };
    },
    components: {
        Footer, NavBar, Menu, vSelect, swal
    },
    data() {
        return {
            halamanloading: true,
            iduser: JSON.parse(localStorage.getItem("usistem")),
            leveluser: JSON.parse(localStorage.getItem("lsistem")),
            namauser: JSON.parse(localStorage.getItem("nmusistem")),
            secretencData: decodeURIComponent(CryptoJS.AES.decrypt(this.route.params.id, 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString(CryptoJS.enc.Utf8)),
            datapesan: '',
            datamaster: [],
            carikata: '',
            jumlahdata: 0,
            jumlahhalaman: 0,
            mulai: 0,
            halaman: 1,
            folder_foto: process.env.VUE_APP_URL_API + 'parastapainnovation_sta-Gambar?f=',
        }
    },

    methods: {
        async G_tableCSV(namefile) {
            this.ekportexcel = [];
            this.halamanloading = true;
            if (this.datamaster.length == 0) {
                this.ekportexcel.push({
                    "No": '1',
                    "Data": 'Data Tidak Diketemukan',
                });
            }
            else {
                for (let i = 0; i < this.datamaster.length; i++) {
                    this.ekportexcel.push({
                        "No": i + 1,
                        "NamaSungai": this.datamaster[i].NamaSungai,
                        "NamaSTA": this.datamaster[i].NamaSTA,
                        "STA": this.datamaster[i].STA,
                        "LATITUDE ": this.datamaster[i].LATITUDE,
                        "LONGITUDE ": this.datamaster[i].LONGITUDE,

                        "tebing_lereng_longsor_ki": this.datamaster[i].lereng_longsor_ki,
                        "tebing_padat_tanaman_ki": this.datamaster[i].padat_tanaman_ki,
                        "tebing_lereng_longsor_ka": this.datamaster[i].lereng_longsor_ka,
                        "tebing_padat_tanaman_ka": this.datamaster[i].padat_tanaman_ka,
                        "tebing_dasar_tergerus": this.datamaster[i].dasar_tergerus,
                        "fungsi_tebing": this.datamaster[i].fungsi_tebing,
                        "sempadan_wilayah_non_kota_alami_ki": this.datamaster[i].non_kota_alami_ki,
                        "sempadan_wilayah_non_kota_berkembang_ki": this.datamaster[i].non_kota_berkembang_ki,
                        "sempadan_wilayah_kota_ki": this.datamaster[i].kota_ki,
                        "sempadan_wilayah_non_kota_alami_ka": this.datamaster[i].non_kota_alami_ka,
                        "sempadan_wilayah_non_kota_berkembang_ka": this.datamaster[i].non_kota_berkembang_ka,
                        "sempadan_wilayah_kota_ka": this.datamaster[i].kota_ka,

                        "pemanfaatan_daerah_sempadan": this.datamaster[i].manfaat_sempadan,
                        "fungsi_sempadan": this.datamaster[i].fungsi_sempadan,
                        "bantaran_sungai_penambangan_sedimen": this.datamaster[i].penambangan_sedimen,
                        "fungsi_bantaran": this.datamaster[i].fungsi_bantaran,
                        "alur_dasar_hambatan_air": this.datamaster[i].hambatan_air,
                        "alur_dasar_penyempitan": this.datamaster[i].penyempitan,
                        "fungsi_alur_dasar": this.datamaster[i].fungsi_dasar,

                        "komponen_sungai_nilaialursungai": this.datamaster[i].nilaialursungai,
                        "komponen_sungai_nilaibantaran": this.datamaster[i].nilaibantaran,
                        "komponen_sungai_nilaitebinglerenglongsor": this.datamaster[i].nilailerenglongsor,
                        "komponen_sungai_nilaitebingpadattanaman": this.datamaster[i].nilaipadattanaman,
                        "komponen_sungai_nilaitebingdasartergerus": this.datamaster[i].nilaidasartergerus,
                        "komponen_sungai_nilaisempadan": this.datamaster[i].nilaisempadan,
                        "komponen_sungai_nilaialursungai": this.datamaster[i].nilaidasar,
                        "komponen_sungai_nilaifungsibantaran": this.datamaster[i].nilaifungsibantaran,
                        "komponen_sungai_nilaifungsitebing": this.datamaster[i].nilaifungsitebing,
                        "komponen_sungai_nilaifungsisempadan": this.datamaster[i].nilaifungsisempadan,

                        "nilaikinerjafisik": this.datamaster[i].nilaikinerjafisik,
                        "nilaikinerjafungsi ": this.datamaster[i].nilaikinerjafungsi,
                        "nilaitotal ": this.datamaster[i].nilaitotal,
                        "kinerja ": this.datamaster[i].kinerja,
                    });
                }
            }

            function formatColumn(worksheet, col, fmt) {
                const range = XLSX.utils.decode_range(worksheet['!ref'])
                // note: range.s.r + 1 skips the header row
                for (let row = range.s.r + 1; row <= range.e.r; ++row) {
                    const ref = XLSX.utils.encode_cell({ r: row, c: col })
                    if (worksheet[ref] && worksheet[ref].t === 'n') {
                        worksheet[ref].z = fmt
                    }
                }
            }
            const currency = '0.00';
            const XLSX = xlsx;
            const workbook = XLSX.utils.book_new();
            const worksheet = XLSX.utils.json_to_sheet(this.ekportexcel);

            // ARRAY NUMBER SILAHKAN MASUKKAN NOMOR KOLOM
            for (let col of [0,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38]) {
                formatColumn(worksheet, col, currency)
            }
            XLSX.utils.book_append_sheet(workbook, worksheet, "STA");
            XLSX.writeFile(workbook, this.G_formatDate(Date(), 'd-M-Y') + namefile + ".xlsx");
            this.halamanloading = false;

        },
        lihatlampiran(filenya) {
            this.halamanloading = true;
            document.getElementById('iframefile').src = "";
            //alert(process.env.VUE_APP_URL_API + foldernya + '?f=' + filenya);
            document.getElementById('iframefile').src = this.folder_foto + filenya;
            $('#costumModaldisclaimer').modal('show');
            this.halamanloading = false;
        },
        getOtentifikasi() {
            var time = Date.parse(localStorage.getItem("tsistem"));
            var timeakhir = time + (3 * 60 * 60 * 1000);
            var timesekarang = Date.parse(new Date());
            if (timesekarang > timeakhir) {
                this.ClearlocalStorage();
            }
        },
        onChangeHalaman(event) {
            this.pindahhalaman(event.target.value);
        },
        pindahhalaman(halamannya) {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            mainAPI.get("parastapainnovation_sta-GetSTA?random=" + random + "&halaman=" + halamannya + "&sungai=" + this.secretencData).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datamaster = [];
                        this.datamaster = Response.data.content.data;
                        this.jumlahdata = Response.data.content.jumlahdata;
                        this.jumlahhalaman = Response.data.content.jumlahhalaman;
                        this.mulai = Response.data.content.mulai;
                        if (this.mulai == 0) {
                            this.halaman = 1;
                        }
                        else {
                            this.halaman = (this.mulai / 100) + 1;
                        }
                        this.halamanloading = false;
                    }
                    else {
                        this.halamanloading = false;
                    }

                }
            ).catch(error => {
                console.log(error);
                this.datadetail = [];
                this.jumlahdata = 0;
                this.jumlahhalaman = 0;
                this.mulai = 0;
                this.halaman = 1;
                this.halamanloading = false;
            });
        },
        async ambildata() {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPI.get("parastapainnovation_sta-GetSTA?random=" + random + "&halaman=" + this.halaman + "&sungai=" + this.secretencData).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datamaster = [];
                        this.datamaster = Response.data.content.data;
                        this.jumlahdata = Response.data.content.jumlahdata;
                        this.jumlahhalaman = Response.data.content.jumlahhalaman;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            });
        },
        prosesdata(kode) {
            var parameter = kode + '|' + this.secretencData;
            var bytes = encodeURIComponent(CryptoJS.AES.encrypt(parameter, 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString());
            window.location.replace("/ProsesSTA/" + bytes);
        },
        hapus(kode) {
            swal.fire({
                title: "Konfirmasi",
                text: "Yakin Hendak Hapus Data?",
                icon: "warning",
                showCancelButton: true,
                cancelButtonText: "Batal",
                confirmButtonColor: "#0396FF",
                cancelButtonColor: "#ffaa05",
                confirmButtonText: "Hapus"
            }).then((result) => {
                if (result.isConfirmed) {
                    const data = { kode: kode, deletedby: JSON.parse(localStorage.getItem("usistem")) }
                    const mainAPI = axios.create({
                        baseURL: process.env.VUE_APP_URL_API,
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                            "Content-Type": "application/x-www-form-urlencoded",
                        },
                    });
                    mainAPI.post("parastapainnovation_sta-DeleteSTA", data).then(
                        Response => {
                            if (Response.data.response == 'error') {
                                swal.fire('Peringatan', Response.data.message, 'error');
                            }
                            else {
                                swal.fire('Informasi', 'Data telah dihapus..', 'success').then(function () {
                                    window.location.replace("/STA");
                                });
                            }
                        }
                    ).catch(
                        error => {
                            swal.fire('Peringatan', 'Terjadi Kesalahan Format Data', 'error');
                        }
                    )

                }
            });
            return false;
        },
    },
    mounted() {
        this.getOtentifikasi();
        this.ambildata();
    }
}
</script>
<style></style>